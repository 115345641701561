import { render, staticRenderFns } from "./search-form.vue?vue&type=template&id=b91cb34c&scoped=true"
import script from "./search-form.vue?vue&type=script&lang=js"
export * from "./search-form.vue?vue&type=script&lang=js"
import style0 from "./search-form.vue?vue&type=style&index=0&id=b91cb34c&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b91cb34c",
  null
  
)

/* custom blocks */
import block0 from "./search-form.vue?vue&type=custom&index=0&blockType=i18n&lang=yaml"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Fa: require('/home/vsts/work/1/s/components/fa.vue').default,SearchForm: require('/home/vsts/work/1/s/components/search/form.vue').default,ZCard: require('/home/vsts/work/1/s/components/style-guide/card.vue').default})
