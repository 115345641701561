
export default {
  props: {
    verticalAlign: {
      type: String,
      default: 'top'
    },

    rightAlign: {
      type: Boolean,
      default: false
    },

    noBody: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    hasAside() {
      return Boolean(this.$slots.aside)
    }
  }
}
