import { render, staticRenderFns } from "./popular-reviews.vue?vue&type=template&id=21c80131&scoped=true"
import script from "./popular-reviews.vue?vue&type=script&lang=js"
export * from "./popular-reviews.vue?vue&type=script&lang=js"
import style0 from "./popular-reviews.vue?vue&type=style&index=0&id=21c80131&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "21c80131",
  null
  
)

/* custom blocks */
import block0 from "~/locales/common/popular-reviews.yaml?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fhome%2Fvsts%2Fwork%2F1%2Fs%2Fcomponents%2Fcontent%2Fpopular-reviews.vue&lang=yaml&external"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Review: require('/home/vsts/work/1/s/components/review/review.vue').default})
