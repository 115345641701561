
import LazyHydrate from 'vue-lazy-hydration'
import { getReviewSnippet } from '~/lib/jsonld/index.js'

export default {
  name: 'Homepage',

  components: {
    LazyHydrate
  },

  head() {
    const i18nSeo = this.$nuxtI18nHead({ addSeoAttributes: true })

    i18nSeo.titleTemplate = this.$i18n.t('pages.index.metaTitle')

    i18nSeo.link.push({
      rel: 'preload',
      as: 'image',
      type: 'image/webp',
      href: this.heroImage
    })

    return i18nSeo
  },

  computed: {
    heroImage() {
      const suffix = this.$device.isMobile ? '-mobile' : '-desktop'
      const folder = `homepage/herosection-image${suffix}-v2.webp`

      return `${this.$config.staticAssetsURL}/web/images/${folder}`
    }
  },

  mounted() {
    if (this.$route.query?.logout) {
      this.$store.commit('rv/CLEAR_MY_RVS')
      this.$store.commit('favourites/CLEAR_COLLECTIONS')
    }
  },

  jsonld() {
    return {
      '@context': 'https://schema.org',
      '@graph': [
        {
          '@type': 'Website',
          url: 'https://www.rvezy.com',
          potentialAction: {
            '@type': 'SearchAction',
            target: {
              '@type': 'EntryPoint',
              urlTemplate: 'https://www.rvezy.com/rv-search?{search_query}'
            },
            'query-input': {
              '@type': 'PropertyValueSpecification',
              valueRequired: 'http://schema.org/False',
              valueName: 'search_query'
            }
          }
        },
        getReviewSnippet(this)
      ].filter((item) => item)
    }
  }
}
