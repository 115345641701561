import { render, staticRenderFns } from "./media.vue?vue&type=template&id=379f6f1e"
import script from "./media.vue?vue&type=script&lang=js"
export * from "./media.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ZMediaAside: require('/home/vsts/work/1/s/components/style-guide/media-aside.vue').default,ZMediaBody: require('/home/vsts/work/1/s/components/style-guide/media-body.vue').default})
