
export default {
  props: {
    image: {
      type: String,
      required: true
    }
  },

  computed: {
    imageDimensions() {
      return this.$device.isMobile
        ? {
            width: 750,
            height: 1211
          }
        : {
            width: 1600,
            height: 750
          }
    }
  }
}
