
import { isVariant } from '~/lib/experiments/utils.js'
import { ExperimentKeys } from '~/lib/enums/index.js'
import { nextSevenNightsRange } from '~/lib/rvs/index.js'

const NorthAmericaBoundingBox = {
  swLng: '-179.9',
  swLat: '18.8163608007951',
  neLng: '-52.5198306260026',
  neLat: '83.1473634999412'
}

export default {
  props: {
    showByGeolocation: {
      type: Boolean,
      default: true
    }
  },

  data() {
    return {
      pageSize: 10,
      featuredRvs: null,
      searchRequestId: null
    }
  },

  async fetch() {
    this.params.GetSixtyNightAvailability =
      !this.params.StartDate &&
      !this.params.EndDate &&
      isVariant(this.$experiment.getVariant(ExperimentKeys.rvz18749DiscountDisplayFirstTime))

    const { FeaturedRVs } = await this.$axios.$get(`${this.$config.apiUrl}/api/rvlistings/unified-search`, {
      params: this.params
    })

    this.searchRequestId = FeaturedRVs.Id

    this.featuredRvs = FeaturedRVs.ListRVs
  },

  computed: {
    params() {
      const params = {
        PageSize: 0,
        FeaturedPageSize: this.pageSize
      }

      if (this.showByGeolocation) {
        const { latitude, longitude, countryCode } = this.$geolocation
        const { supportedCountries } = this.$config
        if (latitude && longitude && countryCode && supportedCountries.includes(countryCode.toLowerCase())) {
          return {
            ...params,
            SearchLat: latitude,
            SearchLng: longitude,
            country: countryCode
          }
        }
      }

      params.GetSixtyNightAvailability =
        !params.StartDate &&
        !params.EndDate &&
        isVariant(this.$experiment.getVariant(ExperimentKeys.rvz18749DiscountDisplayFirstTime))

      return {
        ...params,
        ...NorthAmericaBoundingBox
      }
    },

    showMeMoreLink() {
      let query

      return this.localePath({ name: 'rv-search', query })
    }
  },

  methods: {
    nextSevenNightsRange(rv) {
      //rvz18749DiscountDisplayFirstTime - This file was migrated to Nuxt 3. If this experiments wins, implement this on Nuxt 3: https://rvezy.atlassian.net/browse/RVZ-19183
      return nextSevenNightsRange(rv)
    }
  },

  fetchOnServer: false
}
