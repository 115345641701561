
import { RouteBaseNameEnum } from '~/lib/enums'
import useToDistance from '~/lib/useToDistance'

export default {
  data() {
    return {
      RouteBaseNameEnum: RouteBaseNameEnum.$all()
    }
  },

  computed: {
    closestDestinations() {
      const destinations = [
        {
          slug: 'united-states/florida/miami',
          City: 'Miami',
          Region: 'Florida',
          Image: `${this.$config.staticAssetsURL}/destinations/miami.webp`,
          Latitude: 25.77427,
          Longitude: -80.19366
        },
        {
          slug: 'united-states/florida/orange-county',
          City: 'Orange County',
          Region: 'Florida',
          Image: `${this.$config.staticAssetsURL}/destinations/port-orange.webp`,
          Latitude: 28.4844995,
          Longitude: -81.2518833
        },
        {
          slug: 'united-states/california/los-angeles',
          City: 'Los Angeles',
          Region: 'California',
          Image: `${this.$config.staticAssetsURL}/destinations/los-angeles.webp`,
          Latitude: 34.05223,
          Longitude: -118.24368
        },
        {
          slug: 'united-states/texas/dallas',
          City: 'Dallas',
          Region: 'Texas',
          Image: `${this.$config.staticAssetsURL}/destinations/dallas.webp`,
          Latitude: 32.78306,
          Longitude: -96.80667
        },
        {
          slug: 'united-states/california/san-diego',
          City: 'San Diego',
          Region: 'California',
          Image: `${this.$config.staticAssetsURL}/destinations/san-diego.webp`,
          Latitude: 32.71533,
          Longitude: -117.15726
        },
        {
          slug: 'united-states/nevada/las-vegas',
          City: 'Las Vegas',
          Region: 'Nevada',
          Image: `${this.$config.staticAssetsURL}/destinations/las-vegas.webp`,
          Latitude: 36.17497,
          Longitude: -115.13722
        },
        {
          slug: 'canada/british-columbia/vancouver',
          City: 'Vancouver',
          Region: 'British Columbia',
          Image: `${this.$config.staticAssetsURL}/destinations/vancouver.webp`,
          Latitude: 49.262428,
          Longitude: -123.1155399
        },
        {
          slug: 'canada/ontario/toronto',
          City: 'Toronto',
          Region: 'Ontario',
          Image: `${this.$config.staticAssetsURL}/destinations/toronto.webp`,
          Latitude: 43.70011,
          Longitude: -79.4163
        },
        {
          slug: 'canada/quebec/quebec-city',
          City: 'Quebec City',
          Region: 'Quebec',
          Image: `${this.$config.staticAssetsURL}/destinations/quebec-city.webp`,
          Latitude: 46.81228,
          Longitude: -71.21454
        },
        {
          slug: 'canada/ontario/ottawa',
          City: 'Ottawa',
          Region: 'Ontario',
          Image: `${this.$config.staticAssetsURL}/destinations/ottawa.webp`,
          Latitude: 45.41117,
          Longitude: -75.69812
        },
        {
          slug: 'canada/quebec/montreal',
          City: 'Montreal',
          Region: 'Quebec',
          Image: `${this.$config.staticAssetsURL}/destinations/montreal.webp`,
          Latitude: 45.50884,
          Longitude: -73.58781
        },
        {
          slug: 'canada/alberta/calgary',
          City: 'Calgary',
          Region: 'Alberta',
          Image: `${this.$config.staticAssetsURL}/destinations/calgary.webp`,
          Latitude: 51.05011,
          Longitude: -114.08529
        },
        {
          slug: 'canada/alberta/edmonton',
          City: 'Edmonton',
          Region: 'Alberta',
          Image: `${this.$config.staticAssetsURL}/destinations/edmonton.webp`,
          Latitude: 53.55014,
          Longitude: -113.46871
        },
        {
          slug: 'canada/nova-scotia/halifax',
          City: 'Halifax',
          Region: 'Nova Scotia',
          Image: `${this.$config.staticAssetsURL}/destinations/halifax.webp`,
          Latitude: 44.6491198,
          Longitude: -63.5751339
        }
      ]
      if (this.$geolocation.latitude && this.$geolocation.longitude && this.$geolocation.countryCode) {
        destinations.sort((a, b) => {
          const distanceA = useToDistance({
            locale: this.$i18n.locale,
            lat1: this.$geolocation.latitude,
            lon1: this.$geolocation.longitude,
            lat2: a.Latitude,
            lon2: a.Longitude
          })
          const distanceB = useToDistance({
            locale: this.$i18n.locale,
            lat1: this.$geolocation.latitude,
            lon1: this.$geolocation.longitude,
            lat2: b.Latitude,
            lon2: b.Longitude
          })

          return distanceA - distanceB
        })

        return destinations.slice(0, 6)
      }

      const randomDestinations = []
      while (randomDestinations.length < 6) {
        const randomIndex = Math.floor(Math.random() * destinations.length)
        randomDestinations.push(destinations[randomIndex])
        destinations.splice(randomIndex, 1)
      }
      return randomDestinations
    }
  }
}
