import { render, staticRenderFns } from "./review.vue?vue&type=template&id=d6368bcc&scoped=true"
import script from "./review.vue?vue&type=script&lang=js"
export * from "./review.vue?vue&type=script&lang=js"
import style0 from "./review.vue?vue&type=style&index=0&id=d6368bcc&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d6368bcc",
  null
  
)

/* custom blocks */
import block0 from "./review.vue?vue&type=custom&index=0&blockType=i18n&lang=yaml"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ZAvatar: require('/home/vsts/work/1/s/components/style-guide/avatar.vue').default,ZRating: require('/home/vsts/work/1/s/components/style-guide/rating.vue').default,Fa: require('/home/vsts/work/1/s/components/fa.vue').default})
