
import getUserInitials from '@/lib/getUserInitials'
import { ReviewTypeEnumKey } from '~/lib/enums'

export default {
  props: {
    review: {
      type: Object,
      required: true
    },

    contentDetached: {
      type: Boolean,
      default: false
    },

    avatarSize: {
      type: String,
      default: 'small'
    },

    maxLines: {
      type: Number,
      default: null
    },

    showAvatar: {
      type: Boolean,
      default: true
    },

    // TODO: this should not be a prop, but the image should be an object with
    // those properties defined.
    imageWidth: {
      type: Number,
      default: 300
    },

    // TODO: this should not be a prop, but the image should be an object with
    // those properties defined.
    imageHeight: {
      type: Number,
      default: 200
    },

    hasTranslatedContent: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    cssMaxLinesVars() {
      if (!this.maxLines) {
        return {}
      }
      return {
        '--max-lines': this.maxLines
      }
    },

    size() {
      switch (this.avatarSize) {
        case 'small':
          return 3
        case 'medium':
          return 4
        case 'large':
          return 6
      }
      return 3
    },

    initials() {
      return getUserInitials(this.review.Reviewer)
    },

    isRegularReviewType() {
      return this.review.ReviewType === ReviewTypeEnumKey.Regular
    },

    userName() {
      return `${this.review.Reviewer.FirstName}${
        this.review.Reviewer.LastName ? ` ${this.review.Reviewer.LastName.charAt(0)}.` : ''
      }`
    },

    reviewDate() {
      if (this.review.CreatedAt) {
        return `${this.$moment(this.review.CreatedAt).format('MMM YYYY')}`
      }
      return null
    }
  }
}
