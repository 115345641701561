import { render, staticRenderFns } from "./popular-rvs.vue?vue&type=template&id=d22a3b44"
import script from "./popular-rvs.vue?vue&type=script&lang=js"
export * from "./popular-rvs.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./popular-rvs.vue?vue&type=custom&index=0&blockType=i18n&lang=yaml"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CardRv: require('/home/vsts/work/1/s/components/card/rv.vue').default,GridList: require('/home/vsts/work/1/s/components/grid/list.vue').default,ZButton: require('/home/vsts/work/1/s/components/style-guide/button.vue').default})
