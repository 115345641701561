
import { ImageResolution } from '~/lib/enums'
import { getOriginalNightlyRate, getSmartNightlyRate } from '~/lib/rvs/index.js'
import { trackListingSetViewed } from '~/lib/tracking'
import { imageUrl } from '~/lib/useImageUrl'
import absoluteRvUrl from '~/lib/absoluteRvUrl.js'

export default {
  name: 'GridList',

  props: {
    resultsLength: {
      type: Number,
      default: null
    },

    /**
     * The total results to load for a given page.
     */
    pageSize: {
      type: Number,
      default: 5
    },

    /**
     * The amount of results to load data for at once.
     */
    batchSize: {
      type: Number,
      default: 5
    },

    /**
     * The amount of rows to show.
     * Default to 0: no limit.
     */
    rows: {
      type: Number,
      default: 0
    },

    maxColumns: {
      type: Number,
      default: 5
    },

    hideNoResults: {
      type: Boolean,
      default: false
    },

    scrollable: {
      type: Boolean,
      default: false
    },

    pageNumber: {
      type: Number,
      default: 1
    }
  },

  data() {
    return {
      visiblityQueue: [],
      queueTimer: null,

      /**
       * The index used to load incremental batches of results.
       */
      batchIndex: 1
    }
  },

  computed: {
    /**
     * The list of results that have their data displayed.
     */
    currentListOfResults() {
      const batch = this.batchIndex * this.batchSize
      return this.resultsLength !== null && this.resultsLength < batch ? this.resultsLength : batch
    },

    /**
     * Returns either an empty list of results with pregenerated ids, or the actual
     * list of results mapped to those pregenerated ids. This is done so that the
     * keys in the list will never change and the DOM tracks updates correctly
     * instead of re-rendering the card when the results are loaded.
     */
    visibleResults() {
      return [...Array(this.currentListOfResults)].map((_, i) => ({ pregen_id: `someid-${i}` }))
    },

    showNoResults() {
      return !this.hideNoResults && this.noResults
    },

    noResults() {
      return this.resultsLength === 0
    },

    /**
     * Determines if the grid layout should use auto rows, or fixed rows.
     *
     * Even when we use multiple rows, we want to use "auto" rows when there's
     * not enough data to display. This is quite a crude approach, but will work
     * for now.
     */
    shoulduseAutoRows() {
      return this.resultsLength !== null && this.resultsLength <= this.maxColumns && (this.rows === 0 || this.rows > 1)
    },

    computedClasses() {
      return ['grid-list', `max-${this.maxColumns}`, { empty: this.showNoResults, scrollable: this.scrollable }]
    },

    /**
     * The CSS variables for the grid layout.
     */
    cssVars() {
      return {
        '--rows': !this.shoulduseAutoRows && this.rows > 0 ? `repeat(${this.rows}, 1fr)` : 'auto',
        '--auto-rows': !this.shoulduseAutoRows && this.rows > 0 ? '0' : 'auto'
      }
    }
  },

  beforeDestroy() {
    clearTimeout(this.queueTimer)
    this.trackViewableImpressions()
  },

  methods: {
    /**
     * Incrementally loads the next set of cards by increasing the index, but
     * only if the page limit hasn't been reached yet..
     */
    loadNextCards(index) {
      if (index === this.visibleResults.length - 1 && this.currentListOfResults < this.pageSize) {
        this.batchIndex++
      }
    },

    queueViewableImpression({ rv, requestId, cta }) {
      this.visiblityQueue.push(rv)
      // reset the timer everytime something comes into the queue
      clearTimeout(this.queueTimer)
      this.queueTimer = setTimeout(() => {
        this.trackViewableImpressions(requestId, cta)
      }, 5000)
    },

    trackViewableImpressions(requestId, cta) {
      const queueToSend = [...this.visiblityQueue]
      this.visiblityQueue = []

      if (queueToSend.length) {
        trackListingSetViewed({
          cta,
          listingPageNumber: this.pageNumber,
          listingPageSize: this.pageSize,
          searchResults: queueToSend.map((rv) => ({
            heroImage: imageUrl({
              $config: this.$config,
              path: rv?.Photos?.[0]?.Path,
              resolution: ImageResolution.LARGE
            }),
            rvUrl: absoluteRvUrl(this, rv.AliasName),
            isFavourite: Boolean(this.$store.getters['favourites/isRvFavourited'](rv.Id)),
            nightlyRate: getOriginalNightlyRate(rv),
            smartNightlyRate: getSmartNightlyRate(rv),
            ...rv
          })),
          pageSource: this.getRouteBaseName()
        })
      }
    }
  }
}
