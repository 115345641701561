import { render, staticRenderFns } from "./list.vue?vue&type=template&id=2e072156&scoped=true"
import script from "./list.vue?vue&type=script&lang=js"
export * from "./list.vue?vue&type=script&lang=js"
import style0 from "./list.vue?vue&type=style&index=0&id=2e072156&prod&lang=scss"
import style1 from "./list.vue?vue&type=style&index=1&id=2e072156&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2e072156",
  null
  
)

/* custom blocks */
import block0 from "~/locales/common/search/results.yaml?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fhome%2Fvsts%2Fwork%2F1%2Fs%2Fcomponents%2Fgrid%2Flist.vue&lang=yaml&external"
if (typeof block0 === 'function') block0(component)

export default component.exports