import { getFormattedName, getCity, getState, getFormattedDescription, getNumber, getDefaultPrice } from '../rvs'
import { CountryCodeToCurrencyCode } from '~/lib/enums'

export const getProductJson = ({ rv, path, image }) => ({
  '@type': 'Product',
  name: `${getFormattedName(rv)} ${getCity(rv)}, ${getState(rv)}`,
  brand: {
    name: 'RVezy'
  },
  offers: {
    '@type': 'Offer',
    price: `${parseInt(getDefaultPrice(rv))}`,
    priceCurrency: CountryCodeToCurrencyCode(rv.Location.Country),
    availability: 'https://schema.org/InStock',
    url: `https://www.rvezy.com${path}`
  },
  productId: getNumber(rv),
  description: getFormattedDescription(rv),
  image: image
})

export const getAnswer = ({ answer }) => ({
  '@type': 'Answer',
  text: answer
})

export const getQuestion = ({ question, answer }) => ({
  '@type': 'Question',
  name: question,
  acceptedAnswer: getAnswer({ answer })
})

export const getFaq = ({ questions }) =>
  questions?.length
    ? {
        '@type': 'FAQPage',
        mainEntity: questions.map(getQuestion)
      }
    : null

export const getItemListElement = ({ position, name, item }) => ({
  '@type': 'ListItem',
  position: position,
  name: name,
  item: item
})

export const getBreadcrumbList = ({ itemList }) => ({
  '@type': 'BreadcrumbList',
  itemListElement: itemList
})

export const getReviewSnippet = ({ $store }) => {
  const aggregateReview = $store.getters['verifiedReview/aggregateReview']
  return {
    '@type': 'Product',
    name: 'RVezy',
    brand: {
      '@type': 'Organization',
      name: 'RVezy'
    },
    aggregateRating: {
      '@type': 'AggregateRating',
      ratingValue: aggregateReview.rating,
      bestRating: '5',
      reviewCount: aggregateReview.reviewCount
    }
  }
}
