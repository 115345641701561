
export default {
  props: {
    verticalAlign: {
      type: String,
      default: 'top'
    },

    rightAlign: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    verticalAlignClass() {
      return `zmedia-aside-valign-${this.verticalAlign}`
    },

    rightAlignClass() {
      if (this.rightAlign) return `zmedia-aside-right`
      return undefined
    }
  }
}
