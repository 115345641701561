
import { ObserveVisibility } from 'vue-observe-visibility'
import ownerCommon from '~/mixins/owner-common.js'

export default {
  directives: {
    ObserveVisibility
  },

  mixins: [ownerCommon],

  data() {
    return {
      blogSections: [
        {
          buttonTo: this.localePath('blog')
        },
        {
          buttonTo: this.localePath('rv-search')
        },
        {
          buttonText: this.$t('tellMeMore'),
          buttonTo: this.localePath(this.$auth.loggedIn ? 'dashboard-rvs-new' : 'owner')
        }
      ]
    }
  },

  methods: {
    chooseImg(index) {
      if (index === 1) return `${this.$config.staticAssetsURL}/web/images/buy-an-rv/buy-an-rv-homepage-banner.webp`
      return `${this.$config.staticAssetsURL}/web-app/blog${index + 1}.webp`
    }
  }
}
