
import useRoundAverageRating from '~/lib/useRoundAverageRating'
export default {
  props: {
    value: {
      type: Number,
      required: true
    },

    iconVariant: {
      type: String,
      default: 'primary'
    },

    bold: {
      type: Boolean,
      default: false
    },

    roundAverage: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    rating() {
      const value = this.value.toFixed(1)

      if (!this.roundAverage) {
        return value
      }
      return useRoundAverageRating(value)
    }
  }
}
