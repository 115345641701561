
import { ObserveVisibility } from 'vue-observe-visibility'

import { SearchTypes } from '~/lib/enums'

export default {
  name: 'LandingPageSearchForm',

  directives: {
    ObserveVisibility
  },

  data() {
    return {
      openCalendar: false,
      openGuestPicker: false,
      tabsFilter: SearchTypes.SearchAll
    }
  },

  computed: {
    tabFilters() {
      return SearchTypes
    }
  },

  beforeDestroy() {
    this.$nuxt.$emit('show-header-search-form', true)
  },

  methods: {
    selectTab(tab) {
      this.animateChangeTab()

      this.tabsFilter = tab
    },

    animateChangeTab() {
      this.$refs.form.$el.animate(
        [{ opacity: '0.25' }, { opacity: '0.4' }, { opacity: '0.5' }, { opacity: '0.75' }, { opacity: '1' }],
        {
          duration: 500
        }
      )
    },

    visibilityToggle(visible) {
      this.$nuxt.$emit('show-header-search-form', !visible)
    }
  }
}
