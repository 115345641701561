
export default {
  computed: {
    benefits() {
      return [
        {
          name: 'coverage',
          img: 'coverage-v2'
        },
        {
          name: 'support',
          img: 'support-v2'
        },
        {
          name: 'verified',
          img: 'verified-rv-v2'
        },
        {
          name: 'save',
          img: 'save-v2'
        }
      ]
    }
  }
}
