
const reviewsJson = require('@/assets/data/reviews.json')

export default {
  props: {
    alternativeCard: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    reviews() {
      return reviewsJson.map((review, index) => {
        return {
          ...review,
          Content: this.$t(`popularReviews.review${index + 1}`)
        }
      })
    },

    shuffledReviews() {
      const selected = []
      const reviews = []

      while (selected.length < 3) {
        const randomIndex = Math.floor(Math.random() * this.reviews.length)
        if (!selected.includes(randomIndex)) {
          reviews.push(this.reviews[randomIndex])
          selected.push(randomIndex)
        }
      }

      return reviews
    }
  }
}
